
// Utilities
import { sync } from 'vuex-pathify';

// Provider
import {
  authProvider,
  subscriptionProvider
} from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';

export default {
  name: 'AppBar',

  components: {},

  computed: {
    ...(sync('app/*') as any),
    drawer: sync('app/drawer'),

    provider(): any {
      return authProvider(this.$store);
    },

    subscriptionProvider(): any {
      return subscriptionProvider(this.$store);
    }
  }
};
